import { graphql } from "gatsby"
import React, { useState, useEffect, useContext } from "react"
import tw from "twin.macro"
import { LanguageContext } from "../../context/Language"
import { getStringValuesArray, mapValuesToObject } from "../../helpers.js"
import { DefaultPageTemplateProps } from "./default.d"
import TranslateClient from "../../clients/TranslateClient"
import ImageHero from "../../components/Hero/ImageHero"
import { H1 } from "../../components/Typography"
import { BackgroundContainer } from "../../components/BackgroundContainer"
import { SEO } from "../../components/SEO"

const Default: React.FC<DefaultPageTemplateProps> = ({ data, location }) => {
  const { title, content, featuredImage, template } = data?.wpPage
  const heroDescription = template?.defaultPage?.heroDescription

  const [text, setText] = useState({ title, content, heroDescription })
  const { language } = useContext(LanguageContext)

  useEffect(() => {
    if (language === "en") {
      setText({ title, content, heroDescription })
    } else {
      ;(async () => {
        const textToTranslate = getStringValuesArray([
          title,
          content,
          heroDescription,
        ])
        const translations = await TranslateClient.translate(
          textToTranslate,
          language
        )
        const translated = mapValuesToObject(text, translations)
        // Removing extra spaces and inaccurate content that is due to a google translate bug.
        // https://issuetracker.google.com/issues/119256504
        const correctedContent = translated?.content
          .replaceAll("< ", "<")
          .replaceAll("inline-t ", "inline-t")
          .replaceAll(" >", ">")
          .replaceAll("</ ", "</")
          .replaceAll(" td>", "</td>")
          .replaceAll("figur ", "figure")
          .replaceAll("figura", "figure")
          .replaceAll("figurecl", "figure")
          .replaceAll("<figure cl ", "<figure ")
          .replaceAll("clase=", "class=")
          .replaceAll("tabla", "table")
          .replaceAll(" ass", " class")
          .replaceAll(
            "wp-block-t fähige Inline-Tabelle",
            "wp-block-table inline-table"
          )
          .replaceAll("wp-block-t内联表", "wp-block-table inline-table")
          .replaceAll("wp-block-t內聯表", "wp-block-table inline-table")
        if (translated.content) {
          translated.content = correctedContent
        }
        setText(translated)
      })()
    }
  }, [language])

  return (
    <div className="page-content">
      <SEO url={location?.href} title={title} />
      <ImageHero image={featuredImage} overlay={false}>
        <div css={tw`text-center text-white container m-auto p-4 md:p-8`}>
          <H1
            css={[
              tw`text-4xl font-extrabold sm:text-5xl xl:text-6xl`,
              "text-shadow: 0 2px 4px rgba(0, 0, 0, 0.6);",
            ]}
          >
            {text?.title}
          </H1>
          <p css={tw`text-lg font-medium lg:mb-4`}>{text?.heroDescription}</p>
        </div>
      </ImageHero>
      <BackgroundContainer css={tw`py-16`}>
        <section
          css={tw`container m-auto px-4 space-y-4 md:p-8`}
          className="li-styling-supported three-column-support"
          dangerouslySetInnerHTML={{ __html: text?.content }}
        />
      </BackgroundContainer>
    </div>
  )
}

export const query = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      content
      id
      featuredImage {
        node {
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
      template {
        ... on WpDefaultTemplate {
          templateName
          defaultPage {
            heroDescription
          }
        }
      }
    }
  }
`

export default Default
